import axios from 'axios';
import {API_URL} from '../utils/constants';
import {toast} from 'react-toastify';

const instance = axios.create({
  baseURL: API_URL,
  contentType: 'application/json',
  Authorization: getAuthToken(),
  timezoneoffset: new Date().getTimezoneOffset(),
});

function getAuthToken() {
  if (localStorage.getItem('auth_token')) {
    return `Bearer ${localStorage.getItem('auth_token')}`;
  }
}

/** Getting refresh token and retry */
const parseError = (error) => {
  if (
    error.response.data.message ===
    "Cannot destructure property 'type' of 'jwt.decode(...)' as it is null."
  ) {
    toast.info('new version updated, auto logging out');
    setTimeout(() => {
      window.localStorage.clear();
      window.location.reload();
    }, 1000);
  }
  return Promise.reject(error);
};

/** Configuration for headers */
/**
 * @param {object} config
 * @return {object}
 */
const parseConfig = (config) => {
  const token = localStorage.getItem('auth_token')
    ? localStorage.getItem('auth_token')
    : '';
  const configuration = {...config};
  configuration.headers['Content-type'] = 'application/json';
  configuration.headers['Authorization'] = `Bearer ${token}`;
  configuration.headers['timezoneoffset'] = new Date().getTimezoneOffset();
  return configuration;
};

// request header
instance.interceptors.request.use(
  (config) => parseConfig(config),
  (error) => Promise.reject(error)
);

// response parse
instance.interceptors.response.use(
  (response) => response,
  (error) => parseError(error)
);

export const http = instance;
